import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { withRouter } from "react-router-dom";
import '../App.css';
import '../Grid.css';
import Navigationbar from '../Navigationbar';
import 'bootstrap/dist/css/bootstrap.css';
import { Card, Button } from "react-bootstrap";
import { getToken, removeUserSession } from '../utils/SessionManager';
import Avatar from 'react-avatar';
import 'react-dropdown/style.css';
import Swal from 'sweetalert2'

class Patients extends Component {

    constructor(props) {
        super(props)
        this.state = {
            patients: [],
        }
    }

    componentDidMount() {

        if (getToken() !== null) {
            this._getAllPatients();
        }

    }

    _getAllPatients = () => {
        var authToken = getToken();

        //this.setState({ isLoading: true })
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("authorization", 'JWT ' + authToken);

        fetch('http://18.216.10.39:3000/api/patient/getallpatients', {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        }).then((response) => {
            if (response.status === 200) {
                return response.text()
            } else if (response.status === 403) {
                removeUserSession();
                this.props.history.push('/login')
            } else {
                alert('Something went wrong. Please try again later.')
            }
        })
            .then((responseJson) => {
                var patientResponse = JSON.parse(responseJson);
               // console.log("parsed data" + JSON.stringify(patientResponse));
                this.setState({ patients: patientResponse })
            })
            .catch(error => {
                console.error(error);
                alert(error)
            });

    }

    handleEditPatient = (patientDetails) => {

        const { PKEY, PATIENT_NAME, PATIENT_ID, FKEY_PATIENT_LOCATION, PATIENT_ADDRESS } = patientDetails

        const editPatient = {
            patientName: PATIENT_NAME,
            patientId: PATIENT_ID,
            patientLocation: FKEY_PATIENT_LOCATION,
            patientAddress: PATIENT_ADDRESS,
            patientKey: PKEY
        }
        sessionStorage.setItem('editPatientDetails', JSON.stringify(editPatient));
        this.props.history.push('/patientdetails')
    }

    handleAddPatient = () => {
        sessionStorage.setItem('editPatientDetails', null);
        this.props.history.push('/patientdetails')   
    }

    showDeleteAlert = (userKey) => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'Do you want to delete this patient?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Delete',
            cancelButtonText: 'Cancel'
        }).then((result) => {
            if (result.value) {
                this._deleteStaff(userKey);

            }
        })
    }

    //Delete the staff
    _deleteStaff = (userKey) => {
        var dataToSend = {
            PATIENT_KEY: userKey
        };
        var authToken = getToken();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("authorization", 'JWT ' + authToken);

        fetch('http://18.216.10.39:3000/api/patient/deletepatient', {
            method: 'DELETE',
            body: JSON.stringify(dataToSend),
            headers: myHeaders,
            redirect: 'follow'
        }).then((response) => {
            if (response.status === 200) {
                return response.text()
            } else {
                this.setState({ animating: false })
                alert('Something went wrong. Please try again later.')
            }
        }).then((jsonResponse) => {
            Swal.fire({
                title: 'Success!',
                text: 'Staff has been deleted.',
                icon: 'success',
                confirmButtonText: 'OK',
            }).then((result) => {
                if (result.value) {
                    this._getAllPatients()
                }
            })


        }).catch((error) => {
        })

    }

    renderCard = (card, index) => {
        
        const { PKEY, PATIENT_NAME, PATIENT_ID, FKEY_PATIENT_LOCATION, PATIENT_ADDRESS } = card

        return (
            <div className="row " >
                <Card style={{ width: "16rem" }} key={index} className='box shadow' >

                    <div style={{ marginTop: 5, marginRight: 10, alignSelf: 'flex-end', }}>
                        <img width='30' src={require('../resources/menu.png')} onClick={() => { this.showDeleteAlert(PKEY) }}
                            onMouseOver={e => (e.currentTarget.src = require('../resources/staff_avatar.png'))}
                            onMouseOut={e => (e.currentTarget.src = require('../resources/menu.png'))}
                        />
                    </div>

                    <div style={{ flex: 1, marginTop: 5, alignSelf: 'center', }}>
                        <Avatar size='80' round={true} src={require('../resources/staff_avatar.png')} />
                    </div>
                    <Card.Body >

                        <Card.Title style={{ textAlign: 'center', color: '#09283B' }} > {PATIENT_NAME}</Card.Title>
                        <Card.Text style={{ textAlign: 'center', color: 'grey' }}>{PATIENT_ID}</Card.Text>
                        <Card.Text style={{ textAlign: 'center', color: 'grey' }}>Location: {PATIENT_ADDRESS}</Card.Text>
                        <div class="d-flex justify-content-center">
                            <Button style={{ flex: 1, alignSelf: 'center' }} onClick={() => { this.handleEditPatient(card) }}>Edit Patient</Button>
                        </div>
                    </Card.Body>
                </Card>
            </div>

        );
    };

    render() {

        return (

            <div>
                <div className="container">
                    <Navigationbar history={this.props.history} />
                </div>

                <div className="row" style={{ flex: 1, marginTop: 100, flexDirection: 'row' }}>
                    <h5 className="col-12 col-lg-6 mx-4" style={{ paddingLeft: 60 }}>Patients</h5>
                    <div className="col-5 col-lg-5 mr-6" style={{ display: 'flex', justifyContent: 'flex-end', textAlign:'center' }}>
                    <button type="submit" className="btn text-uppercase " style={{ backgroundColor: '#E30052', color: '#fff',}} onClick={() => { this.handleAddPatient() }} ><b>Add Patient</b></button>
                    </div>
                </div>

                <div >
                    <div className="grid" style={{ flex: 1, paddingLeft: 50 }}>{this.state.patients.map(this.renderCard)}</div>
                </div>
            </div>

        );
    }
}

ReactDOM.render(<Patients />, document.getElementById('root'));
export default withRouter(Patients);


