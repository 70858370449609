import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { withRouter, Redirect } from "react-router";
import Navigationbar from './Navigationbar';
import './App.css';
import 'bootstrap/dist/css/bootstrap.css';
import { getToken, removeUserSession } from './utils/SessionManager';


class Dashboard extends Component {


    goToStaff = () => {
        this.props.history.push('/staff')
    }

    goToPatients = () => {
        this.props.history.push('/patients')
    }

    goToLocations = () => {
        this.props.history.push('/locations')
    }

    goToAttendance = () => {
        this.props.history.push('/staff/attendance')
    }

    goToScheduling = () => {
        this.props.history.push('/schedule')
    }


    render() {

        return (

            <div className="container-fluid py-5">
                <div className="container">
                    <Navigationbar history={this.props.history} />
                </div>

                <div className="mt-5">
                    <div className="row" style={{ flex: 1, justifyContent: 'center' }}>
                        <a className="col-12 col-lg-3 mx-n3" >

                            <div onClick={this.goToStaff}>
                                <span className="shadow">
                                    <img style={{ width: 280, height: 280 }} alt="staff list" src={require("./resources/staff.png")} />
                                </span>
                                <h3 className="h6 text-center font-weight-bold mt-2">STAFF</h3>
                            </div>
                        </a>
                        <a className="col-12 col-lg-3 mx-n3" >
                            <div onClick={this.goToAttendance}>
                                <span className="shadow">
                                    <img style={{ width: 280, height: 280 }} alt="attendance" src={require("./resources/attendance.png")} />
                                </span>
                                <h3 className="h6 text-center font-weight-bold mt-2">ATTENDANCE</h3>
                            </div>
                        </a>
                        <a className="col-12 col-lg-3 mx-n3" >
                            <div onClick={this.goToPatients}>
                                <span className="shadow">
                                    <img style={{ width: 280, height: 280 }} alt="holidays" src={require("./resources/patient.png")} />
                                </span>
                                <h3 className="h6 text-center font-weight-bold mt-2">PATIENTS</h3>
                            </div>
                        </a>
                        <a className="col-12 col-lg-3 mx-n3" >
                            <div onClick={this.goToLocations}>
                                <span className="shadow">
                                    <img style={{ width: 280, height: 280 }} alt="leave" src={require("./resources/location.png")} />
                                </span>
                                <h3 className="h6 text-center font-weight-bold mt-2">LOCATIONS</h3>
                            </div>
                        </a>

                        <a className="col-12 col-lg-3 mx-n3" >
                            <div onClick={this.goToScheduling}>
                                <span className="shadow">
                                    <img style={{ width: 280, height: 280 }} alt="leave" src={require("./resources/scheduling.png")} />
                                </span>
                                <h3 className="h6 text-center font-weight-bold mt-2">SCHEDULE</h3>
                            </div>
                        </a>

                    </div>
                </div>
            </div>
        )
    }

}

ReactDOM.render(<Dashboard />, document.getElementById('root'));
export default withRouter(Dashboard);


