import React, { Component, Fragment, PureComponent } from 'react';
import ReactDOM from 'react-dom';
import '../App.css';
import { Text, View, StyleSheet, Page, Document, BlobProvider, PDFDownloadLink } from '@react-pdf/renderer';
import { PdfDocument } from "../PdfGenerator";
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import DatePicker from "react-datepicker";
import Navigationbar from '../Navigationbar';
import { getToken } from '../utils/SessionManager';
import "react-datepicker/dist/react-datepicker.css";

class AttendanceView extends Component {

    constructor(props) {
        super(props)
        this.state = {
            routerLocation: "",
            attendance: [],
            totalTimeWorked: '',
            userAttendancePdf: [],
            attendanceHeading: [
                { No: '', Date: '', "Sign In": 'Arun', "Sign Out": '', Time: 21 },
            ],
            excelData: [],
            startDate: '',
            endDate: '',
        }
    }

    componentDidMount() {

        // var id = this.props.location;
        // if (id !== undefined) {
        //     this.setState({ routerLocation: id.state }, () => {
        //         this.getAttendance();
        //     })
        //     console.log("datsdsa" + JSON.stringify(id.state));
        // }
        const attendanceUser = JSON.parse(sessionStorage.getItem('attendanceView'))

        if (attendanceUser !== null) {
            this.setState({ routerLocation: attendanceUser }, () => {
                this.getAttendance();
            })
        }

        this.setState({
            startDate: new Date().setDate(1)
        })

        this.setState({
            endDate: new Date()
        })
    }

    getAttendance = () => {

        var userKey = this.state.routerLocation.userKey
        var authToken = getToken();

        var date_object_from = new Date(this.state.startDate);
        var date_object_to = new Date(this.state.endDate);

        var dateFrom = date_object_from.getFullYear() + '-' + parseInt(date_object_from.getMonth() + 1) + '-' + date_object_from.getDate()
        + ' ' + date_object_from.getHours() + ':' + date_object_from.getMinutes() + ':' + date_object_from.getSeconds()

    var dateTo = date_object_to.getFullYear() + '-' + parseInt(date_object_to.getMonth() + 1) + '-' + date_object_to.getDate()
        + ' ' + date_object_to.getHours() + ':' + date_object_to.getMinutes() + ':' + date_object_to.getSeconds()


        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("authorization", 'JWT ' + authToken);

        // var dataToSend = {
        //     FKEY_USER: userKey, ATTENDENCE_TIME_FROM: "2020-10-01 09:00:0", ATTENDENCE_TIME_TO: "2020-10-05 09:00:0"
        // };

        var dataToSend = {
            FKEY_USER: userKey, ATTENDENCE_TIME_FROM: dateFrom, ATTENDENCE_TIME_TO: dateTo
        };

        fetch('http://18.216.10.39:3000/api/users/getattendencebyrangebyid', {
            method: 'POST',
            body: JSON.stringify(dataToSend),
            headers: myHeaders,
        }).then((response) => {
            if (response.status === 200) {
                return response.text()
            } else {
                alert('Something went wrong. Please try again later.')
            }
        }).then((jsonResponse) => {
            if (jsonResponse === undefined) {
                return;
            }
            var responseObject = JSON.parse(jsonResponse);
          //  console.log("attendance view response" + JSON.stringify(responseObject))

            this.setState({ attendance: responseObject },
                () => {
                    this.sortData(responseObject);
                })

        }).catch((error) => {
            alert(error)
        })
    }

    sortData = (userAttendance) => {
        var attendance = userAttendance;
        var timeWorked = 0;
        var userAttendancePdf = [];

        for (var i = 0; i < attendance.length; i++) {
            var timediff = attendance[i].TIME_DIFFERENCE
            timeWorked = timeWorked + timediff
            userAttendancePdf.push({
                userKey: attendance[i].FKEY_USER,
                signIn: attendance[i].SIGN_IN,
                signOut: attendance[i].SIGN_OUT,
                id: this.state.routerLocation.employeeId,
                name: this.state.routerLocation.employeeName,
                time: timediff,
                date: attendance[i].SIGN_IN,
                signInLatitude: attendance[i].IN_LATITUDE,
                signInLongitude: attendance[i].IN_LONGITUDE,
                signOutLatitude: attendance[i].OUT_LATITUDE,
                signOutLongitude: attendance[i].OUT_LONGITUDE,
                designation: this.state.routerLocation.designation,
            })
        }
        this.setState({ totalTimeWorked: timeWorked, userAttendancePdf: userAttendancePdf })
    }

    handleStartDateChange = (date) => {
        if (date <= this.state.endDate) {
            this.setState({
                startDate: date
            }, () => { this.getAttendance() })
            sessionStorage.setItem('startDate', date);
        } else {
            alert("Start date cannot be greater than end date");
        }

    }

    handleEndDateChange = (date) => {
        if (date >= this.state.startDate) {
            this.setState({
                endDate: date
            }, () => { this.getAttendance() })
            sessionStorage.setItem('endDate', date);
        } else {
            alert("End date cannot be lesser than start date");
        }
    }

    render() {

        var name = this.state.routerLocation.employeeName
        var id = this.state.routerLocation.employeeId
        var designation = this.state.routerLocation.designation

        var timeWorked = this.state.totalTimeWorked

        return (

            <Fragment>
                <div className="container py-4">

                    <div >
                        <Navigationbar history={this.props.history} />
                    </div>
                    <div style={{ marginTop: 80 }}>

                        <View style={{ display: 'flex', flex: 1, }}>
                            <div style={{ display: 'flex', flex: 1, justifyContent: 'flex-start' }}>
                                <h5 >{name}</h5>
                            </div>
                            {this.state.userAttendancePdf.length !== 0 ?
                                <div style={{ display: 'flex', flexDirection: 'row', flex: 1, justifyContent: 'flex-end' }}>
                                    <View style={{ display: 'flex', flexDirection: 'row', marginRight: 25, justifyContent: 'flex-end' }}>

                                        <img style={{ height: 24, alignSelf: 'center', marginRight: 10 }} src={require('../resources/pdf_icon.png')} />
                                        <PDFDownloadLink
                                            style={{ alignSelf: 'flex-end', alignSelf: 'center', color: '#09283B' }}
                                            document={<PdfDocument data={this.state.userAttendancePdf} />}
                                            fileName={name + '_' + id + ".pdf"}>
                                            {({ blob, url, loading, error }) =>
                                                this.state.downloadButton ? "Loading document..." : "Download PDF"
                                            }
                                        </PDFDownloadLink>
                                    </View>
                                    <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>

                                        <img style={{ height: 24, alignSelf: 'center', marginRight: 10 }} src={require('../resources/excel_icon.png')} />

                                        <Text style={{ color: '#09283B' }} onClick={() => { this.downloadExcel(this.excelDataFormatter(this.state.userAttendancePdf), name + '_' + id) }} ><a>Download Excel</a></Text>
                                    </View>
                                </div> : null}
                        </View>


                        {/* <View style={{ display: 'flex', marginTop: 30, justifyContent: 'center', flexDirection: 'column' }}>
                                <text style={{ flex: 1, alignItems: 'flex-start' }}> {'Name: ' + name}</text>
                                <text style={{ flex: 1, alignItems: 'flex-start' }}> {'Id: ' + id}</text>
                                <text style={{ flex: 1, alignItems: 'flex-start' }}> {'Designation: ' + designation}</text>
                                <text style={{ flex: 1, alignItems: 'flex-start' }}> {'Total hours: ' + this.getWorkingHours(timeWorked)}</text>
                            </View> */}

                        <View style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'row', marginTop: 30, marginBottom: 30 }}>
                            <View style={{ display: 'flex', flexDirection: 'column' }}>

                                <DatePicker
                                    className="card"
                                    dateFormat="dd-MM-yyyy"
                                    selected={this.state.startDate}
                                    onChange={this.handleStartDateChange}
                                    maxDate={new Date()}
                                />

                            </View>
                            <View style={{ display: 'flex', flexDirection: 'column', marginLeft: 50 }}>

                                <DatePicker
                                    className="card"
                                    dateFormat="dd-MM-yyyy"
                                    selected={this.state.endDate}
                                    onChange={this.handleEndDateChange}
                                    maxDate={new Date()}
                                />

                            </View>
                        </View>

                        <table className='shadow' style={{ border: 'none', }} id='Users'>
                            {/* <tbody style={{flex:1, flexWrap:'wrap',width:'100%'}} > */}
                            <tbody  >
                                <tr>{this.renderAttendanceTableHeader()}</tr>
                                {this.renderAttendanceTableData()}
                            </tbody>
                        </table>
                    </div>
                </div>
            </Fragment>
        );
    }

    getWorkingHours = (timeWorked) => {
        var seconds = timeWorked * 60;
        var hours = parseInt(seconds / 3600);
        seconds = seconds % 3600;
        var minutes = parseInt(seconds / 60); // 60 seconds in 1 minute
        if (hours === 0) {
            var timeWorked = minutes + ' minutes'
        } else {
            if (minutes === 0) {
                var timeWorked = hours + ' hours'
            } else {
                var timeWorked = hours + ' hours : ' + minutes + ' minutes'
            }
        }
        return timeWorked;
    }

    getFormattedDate = (date) => {
        var date_format = new Date(date);
        return date_format.getDate() + '-' + (date_format.getMonth() + 1) + '-' + date_format.getFullYear()
    }

    getSignTime = (date) => {
        var date_format = new Date(date);
        return date_format.getHours() + ':' + date_format.getMinutes()
    }

    downloadExcel = (csvData, fileName) => {
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';
        const ws = XLSX.utils.json_to_sheet(csvData);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
    }

    excelDataFormatter = (userAttendance) => {
        var excelAttendance = []
        userAttendance.map((item, index) => {
            excelAttendance.push({
                // id: item.id,
                // name: item.name,
                // designation: item.designation,
                Date: this.getFormattedDate(item.date),
                'Sign In': this.getSignTime(item.signIn),
                'Sign Out': this.getSignTime(item.signOut),
                Time: this.getWorkingHours(item.time),
            })
        })
        this.setState({ excelData: excelAttendance })
        return excelAttendance;

    }

    renderAttendanceTableHeader = () => {
        let header = Object.keys(this.state.attendanceHeading[0])
        return header.map((key, index) => {
            return <th style={{ border: 'none', backgroundColor: '#239DFF' }} key={index}>{key}</th>
        })
    }

    renderAttendanceTableData = () => {
        return this.state.userAttendancePdf.map((attendance, index) => {
            const { id, date, time, signIn, signOut } = attendance //destructuring
            return (

                <tr key={id}>
                    <td style={{ border: 'none' }} >{index + 1}</td>
                    <td style={{ border: 'none' }} >{this.getFormattedDate(date)}</td>
                    <td style={{ border: 'none' }} >{this.getSignTime(signIn)}</td>
                    <td style={{ border: 'none' }} >{this.getSignTime(signOut)}</td>
                    <td style={{ border: 'none' }} >{this.getWorkingHours(time)}</td>
                </tr >

            )
        })
    }
}



ReactDOM.render(<AttendanceView />, document.getElementById('root'));
export default AttendanceView;