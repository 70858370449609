import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { withRouter } from "react-router-dom";
import '../App.css';
import '../Grid.css';
import Navigationbar from '../Navigationbar';
import 'bootstrap/dist/css/bootstrap.css';
import { getToken, removeUserSession } from '../utils/SessionManager';
import 'react-dropdown/style.css';
import Swal from 'sweetalert2'

class AddPatients extends Component {

    constructor(props) {
        super(props)
        this.state = {
            registerFormError: "",
            patientName: "",
            patientId: '',
            patientAddress: '',
            patientKey: '',
            showError: false,
            locations: [{
                PKEY: 1000,
                LOCATION: 'Select Location'
            }],
            selectedLocation: ''


        }

        this.handlePatientName = this.handlePatientName.bind(this);
        this.handlePatientId = this.handlePatientId.bind(this);
        this.handleSelectedLocation = this.handleSelectedLocation.bind(this);
        this.handlePatientAddress = this.handlePatientAddress.bind(this);
        this.handleCreateAccountBtn = this.handleCreateAccountBtn.bind(this);

    }

    componentDidMount() {

        const patientDetails = JSON.parse(sessionStorage.getItem('editPatientDetails'))
        if (patientDetails !== null) {

            this.setState({
                patientName: patientDetails.patientName,
                patientId: patientDetails.patientId,
                patientLocation: patientDetails.patientLocation,
                patientAddress: patientDetails.patientAddress,
                patientKey: patientDetails.patientKey

            })
        } else {
            this.setState({
                patientName: '',
                patientId: '',
                patientKey: '',
                patientAddress: '',

            })
        }
        if(getToken() !== null){
        this._getLocations();
        }
    }


    handlePatientName(e) {
        const patientName = e;
        this.setState({
            showError: false,
            registerFormError: "",
            patientName: patientName,
        });
    }

    handlePatientId(e) {
        const patientId = e;
        this.setState({
            showError: false,
            registerFormError: "",
            patientId: patientId,
        });
    }


    handlePatientAddress(e) {
        const patientAddress = e;


        this.setState({
            showError: false,
            registerFormError: "",
            patientAddress: patientAddress,
        });
    }

    handleSelectedLocation(e) {

        this.setState({
            showError: false,
            registerFormError: "",
            selectedLocation: e,
        });
    }


    async handleCreateAccountBtn() {
        const { patientKey, patientName, patientId, selectedLocation, patientAddress } = this.state;

        // const whiteSpaces = /^(?!\s*$)[-a-zA-Z0-9_:,.' ']{1,100}$/;
        const userNameFormate = /^([A-Za-z.\s_-]).{5,}$/;
        const staffIdFormate = /^[0-9a-zA-Z(\-)]+$/;

        if (!patientName.match(userNameFormate)) {
            this.setState({
                showError: true,
                registerFormError: "Please enter a valid name.",
            });
        } else if (!patientId.match(staffIdFormate)) {
            this.setState({
                showError: true,
                registerFormError: "Please enter a valid id.",
            });
        } else if (!selectedLocation.toString().match(staffIdFormate)) {
            this.setState({
                showError: true,
                registerFormError: "Please enter a valid Location.",
            });
        } else if (!patientAddress.match(userNameFormate)) {
            this.setState({
                showError: true,
                registerFormError: "Please enter a valid address.",
            });
        } else {
            if (this.state.patientKey !== '') {
                this._updatePatient();
            } else {
                this._addPatient();
            }
        }
    }

    _getLocations = () => {
        var authToken = getToken();

        //this.setState({ isLoading: true })
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("authorization", 'JWT ' + authToken);

        fetch('http://18.216.10.39:3000/api/location/getalllocations', {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        }).then((response) => {
           // console.log("staff data" + JSON.stringify(response));
            if (response.status === 200) {
                return response.text()
            } else if (response.status === 403) {
                removeUserSession();
                this.props.history.push('/login')
            } else {
                alert('Something went wrong. Please try again later.')
            }
        })
            .then((responseJson) => {
                var locations = JSON.parse(responseJson);
                this.setState({ locations: locations })
            })
            .catch(error => {
                console.error(error);
                alert(error)
            });

    }



    //create the staff
    _addPatient = () => {

        const { patientName, patientId, selectedLocation, patientAddress } = this.state;

        var dataToSend = {
            PATIENT_NAME: patientName, PATIENT_ID: patientId,
            FKEY_PATIENT_LOCATION: selectedLocation, PATIENT_ADDRESS: patientAddress,

        };

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("authorization", 'JWT ' + getToken());

        fetch('http://18.216.10.39:3000/api/patient/createPatient', {
            method: 'POST',
            body: JSON.stringify(dataToSend),
            headers: myHeaders,
            redirect: 'follow'
        }).then((response) => {
           // console.log("add patient response1" + JSON.stringify(response))
            if (response.status === 200) {

                return response.text()
            } else {
                alert('Something went wrong. Please try again later.')
            }
        }).then((jsonResponse) => {
            var responseObject = JSON.parse(jsonResponse);
            this.props.history.goBack()
           // console.log(jsonResponse);

        }).catch((error) => {

        })
    }


    //Update staff details
    _updatePatient = () => {

        const { patientKey, patientName, patientId, selectedLocation, patientAddress } = this.state;

        var dataToSend = {
            PATIENT_KEY: patientKey,
            PATIENT_NAME: patientName, PATIENT_ID: patientId,
            FKEY_PATIENT_LOCATION: selectedLocation, PATIENT_ADDRESS: patientAddress

        };

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("authorization", 'JWT ' + getToken());

        fetch('http://18.216.10.39:3000/api/patient/updatepatient', {
            method: 'PUT',
            body: JSON.stringify(dataToSend),
            headers: myHeaders,
            redirect: 'follow'
        }).then((response) => {
            this.setState({ animating: false })
            if (response.status === 200) {
                return response.text()
            } else {
                alert('Something went wrong. Please try again later.')
            }
        }).then((jsonResponse) => {
            var responseObject = JSON.parse(jsonResponse);

            if (responseObject !== undefined) {
                this.props.history.goBack()
            }

        }).catch((error) => {

        })

    }


    render() {
        const { showError, registerFormError } = this.state;

        var heading = this.state.patientKey !== "" ? "Edit Patient" : "Register Patient";
        var submitButton = this.state.patientKey !== "" ? "Update" : "Create";
        let locationOptions = this.state.locations.map(v => (
            <option value={v.PKEY}>{v.LOCATION}</option>
        ));

        return (

            <div>
                <div className="container">
                    <Navigationbar history={this.props.history} />
                </div>

                <div className="container-fluid py-5 bg-light" style={{ flex: 1, marginTop: 80 }}>
                    <div className="col-lg-6 col-md-6 col-sm-12 mx-auto bg-white shadow p-4">
                        <h2 className="text-center mb-4">{heading}</h2>
                        <form action="javascript:void(0)">
                            <div className="form-row">
                                <div className="form-group col-md-6">
                                    <label htmlFor="userFullName">Full Name</label>
                                    <input type="text" className="form-control" id="userName" placeholder="Full Name"
                                        onChange={e => this.handlePatientName(e.target.value)} value={this.state.patientName} />
                                </div>
                                <div className="form-group col-md-6">
                                    <label htmlFor="userId">Patient Id</label>
                                    <input type="text" className="form-control" id="staffId" placeholder="Patient Id"
                                        onChange={e => this.handlePatientId(e.target.value)} value={this.state.patientId} />
                                </div>
                            </div>

                            <div className="form-row">
                                <div className="form-group col-md-6">
                                    <label htmlFor="designation">Patient Location</label>
                                    <select id="userGender" className="form-control" value={this.state.selectedLocation} onChange={(e) => this.handleSelectedLocation(e.target.value)}>
                                        {locationOptions}
                                    </select>
                                </div>

                                <div className="form-group col-md-6">
                                    <label htmlFor="phoneNumber">Patient Address</label>
                                    <input type="text" className="form-control" id="phoneNumber" placeholder="Patient Address"
                                        onChange={(e) => this.handlePatientAddress(e.target.value)} value={this.state.patientAddress} />
                                </div>

                            </div>

                            <p className="text-danger">{showError ? registerFormError : null}</p>
                            <button type="submit" className=" btn text-uppercase justify-content-center"
                                style={{ backgroundColor: '#157FFB', color: '#fff', flex: 1, alignSelf: 'center' }}
                                onClick={this.handleCreateAccountBtn}><b>{submitButton}</b></button>
                        </form>
                    </div>
                </div>
            </div>

        );
    }
}

ReactDOM.render(<AddPatients />, document.getElementById('root'));
export default withRouter(AddPatients);


