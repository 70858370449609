import React from "react";
import { BrowserRouter as Router, Route, HashRouter } from "react-router-dom";
import Login from "./Login";
import Dashboard from "./Dashboard";
import Staff from './features/Staff'
import AttendanceView from './features/AttendanceView'
import AttendanceHome from './features/AttendanceHome'
import Schedule from './features/Schedule'
import AddStaff from './features/AddStaff'
import Patients from './features/Patients'
import AddPatients from './features/AddPatients'
import Locations from './features/Locations'
import PrivateRoute from "./utils/PrivateRoute";
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.css';

const App = () => {
  return (
      <HashRouter>
        <div>
          <PrivateRoute exact path="/" component={Dashboard} />
          <PrivateRoute exact path="/staff" component={Staff} />
          <PrivateRoute exact path="/staff/attendance" component={AttendanceHome} />
          <PrivateRoute exact path="/attendanceview" component={AttendanceView} />
          <PrivateRoute exact path='/staffdetails' component={AddStaff}/>
          <PrivateRoute exact path='/patients' component={Patients}/>
          <PrivateRoute exact path='/patientdetails' component={AddPatients}/>
          <PrivateRoute exact path='/locations' component={Locations}/>  
          <PrivateRoute exact path='/schedule' component={Schedule}/>
          <Route exact path="/login" component={Login} />
        </div>
      </HashRouter>
  );
};

ReactDOM.render(App, document.getElementById('root'))
export default App;