import React, { Component, useState } from "react";
import { withRouter, Redirect } from "react-router";
import 'bootstrap/dist/css/bootstrap.css';
import { setUserSession, getToken } from './utils/SessionManager';
import './App.css';


class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userLoginEmail: '',
            loggedin: false,
            userLoginPassword: ''
        }
    }

    handleLogin = () => {
        // setUserSession('loginResponse', 'Arun');
        // this.setState({ loggedin: true })

        try {

            var dataToSend = { USER_ID: this.state.userLoginEmail, USER_PASSWORD: this.state.userLoginPassword };

            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            fetch('http://18.216.10.39:3000/api/login/authenticateuser', {
                method: 'POST',
                body: JSON.stringify(dataToSend),
                headers: myHeaders,
                redirect: 'follow'
            }).then((response) => {
                return response.text()
            })
                .then((responseJson) => {
                    //Hide Loader
                    var loginResponse = JSON.parse(responseJson);
                 //   console.log("parsed data" + JSON.stringify(loginResponse));

                    //If server response message same as Data Matched
                    if (loginResponse.token) {
                        setUserSession(loginResponse.token, loginResponse.USER_NAME);
                        this.setState({ loggedin: true })
                    } else {
                        alert(loginResponse.message)
                    }
                })
                .catch(error => {
                    //Hide Loader
                    console.error(error);
                });
        } catch (error) {
            alert(error);
        }
    }



    render() {
        if (getToken()) {
            return <Redirect to="/" />;
        }
        return (
            <div className="bg">
                <div className='container-fluid py-5'>
                    <div style={{
                        position: 'absolute', bottom: 150, width: 300
                    }}>
                        <div style={{ flex: 1, justifyContent: 'center', width: '100%' }}>
                            <img style={{ width: '100%', height: 100, paddingLeft: 40, paddingRight: 40, justifyContent: 'center', marginBottom: 50 }} alt="Logo" src={require("./resources/logo_dummy.png")} />
                        </div>


                        <form action="javascript:void(0)">
                            <div className="form-group">
                                <input name="email" type="email" className="form-control" id="userLoginEmail" placeholder="Email" onChange={(e) => {
                                    this.setState({ userLoginEmail: e.target.value })
                                }}
                                />
                            </div>
                            <div className="form-group">
                                <input name="password" type="password" className="form-control" id="userLoginPassword" placeholder="Password" onChange={(e) => {
                                    this.setState({ userLoginPassword: e.target.value })
                                }} />
                            </div>
                            <div class="text-center mt-4">
                                <button type="submit" className="btn text-uppercase col-lg-12" style={{ backgroundColor: '#E30052', color: '#fff' }} onClick={() => { this.handleLogin() }} ><b>Login</b></button>
                            </div>
                        </form>
                    </div>


                </div>


            </div>

        );
    }
};

export default withRouter(Login);
