import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { withRouter } from "react-router-dom";
import '../App.css';
import '../Grid.css';
import Navigationbar from '../Navigationbar';
import 'bootstrap/dist/css/bootstrap.css';
import { Card, Button } from "react-bootstrap";
import { getToken, removeUserSession } from '../utils/SessionManager';
import Avatar from 'react-avatar';

class AttendanceHome extends Component {

    constructor(props) {
        super(props)
        this.state = {
            users: [],
            attendance: [],
            userAttendancePdf: [],
            heading: [
                { id: 1, name: 'Arun', designation: 21, attendance: 34 },
            ],
        }
    }

    componentDidMount() {

        if (getToken() !== null) {
            this._getAllUsers();
        }

    }

    _getAllUsers = () => {
        var authToken = getToken();

        //this.setState({ isLoading: true })
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("authorization", 'JWT ' + authToken);

        fetch('http://18.216.10.39:3000/api/users/getalluser', {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        }).then((response) => {
            if (response.status === 200) {
                return response.text()
            } else if (response.status === 403) {
                removeUserSession();
                this.props.history.push('/login')
            } else {
                alert('Something went wrong. Please try again later.')
            }
        })
            .then((responseJson) => {
                var staffResponse = JSON.parse(responseJson);
              //  console.log("parsed data" + JSON.stringify(staffResponse));
                this.setState({ users: staffResponse })
            })
            .catch(error => {
                console.error(error);
                alert(error)
            });

    }

    goToAttendance = (STAFF_ID, USER_NAME, USER_DESIGNATION, USER_KEY) => {

        const user = {
            employeeName: USER_NAME,
            employeeId: STAFF_ID,
            designation: USER_DESIGNATION,
            userKey: USER_KEY
        }
        sessionStorage.setItem('attendanceView', JSON.stringify(user));

        this.props.history.push({
            pathname: '/attendanceview', state: {
                employeeName: USER_NAME, employeeId: STAFF_ID, designation: USER_DESIGNATION, userKey: USER_KEY
            }
        })
    }

    renderCard = (card, index) => {
        const { STAFF_ID, USER_NAME, USER_DESIGNATION, USER_KEY } = card

        return (
            <div className="row " >
                <Card style={{ width: "16rem" }} key={index} className='box shadow' >
                    <div style={{ flex: 1, marginTop: 20, alignSelf: 'center', }}>
                        <Avatar size='80' round={true} src={require('../resources/staff_avatar.png')} />
                    </div>
                    <Card.Body >

                        <Card.Title style={{ textAlign: 'center', color: '#09283B' }} > {USER_NAME}</Card.Title>
                        <Card.Text style={{ textAlign: 'center', color: 'grey' }}>{USER_DESIGNATION}</Card.Text>
                        <Card.Text style={{ textAlign: 'center', color: 'grey' }}>EMP {STAFF_ID}</Card.Text>
                        <div class="d-flex justify-content-center">
                            <Button style={{ flex: 1, alignSelf: 'center' }} onClick={() => { this.goToAttendance(STAFF_ID, USER_NAME, USER_DESIGNATION, USER_KEY) }}>View Attendance</Button>
                        </div>
                    </Card.Body>
                </Card>
            </div>

        );
    };

    render() {

        return (

            <div>
                <div className="container">
                    <Navigationbar history={this.props.history} />
                </div>

                <div style={{ flex: 1, marginTop: 100 }}>
                    <h5 style={{ paddingLeft: 60 }}>Staff</h5
                    >
                </div>

                <div >
                    <div className="grid" style={{ flex: 1, paddingLeft: 50 }}>{this.state.users.map(this.renderCard)}</div>
                </div>
            </div>
        );
    }
}

ReactDOM.render(<AttendanceHome />, document.getElementById('root'));
export default withRouter(AttendanceHome);


