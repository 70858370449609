import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { withRouter } from "react-router-dom";
import '../App.css';
import '../Grid.css';
import Navigationbar from '../Navigationbar';
import 'bootstrap/dist/css/bootstrap.css';
import { getToken, removeUserSession } from '../utils/SessionManager';
import 'react-dropdown/style.css';
import Swal from 'sweetalert2'

class AddStaff extends Component {

    constructor(props) {
        super(props)
        this.state = {
            registerFormError: "",
            userProfileImageLable: "Choose image",
            userName: "",
            staffId: '',
            userPhone: '',
            userDesignation: '',
            userEmail: "",
            userPassword: "",
            staffAccess: "Admin",
            userProfileImage: null,
            showError: false,
            userKey: '',
            selectedLocation: '',
            locations: [{
                PKEY: 1000,
                LOCATION: 'Select Location'
            }],
        }

        this.handleUserName = this.handleUserName.bind(this);
        this.handleUserEmail = this.handleUserEmail.bind(this);
        this.handleUserPassword = this.handleUserPassword.bind(this);
        this.handleUserPhone = this.handleUserPhone.bind(this);
        this.handleStaffId = this.handleStaffId.bind(this);
        this.handleDesignation = this.handleDesignation.bind(this);
        this.handleStaffAccess = this.handleStaffAccess.bind(this);
        this.handleCreateAccountBtn = this.handleCreateAccountBtn.bind(this);
        this.handleUserProfileImage = this.handleUserProfileImage.bind(this);
        this.handleSelectedLocation = this.handleSelectedLocation.bind(this);

    }

    componentDidMount() {

        const staffDetails = JSON.parse(sessionStorage.getItem('editStaffDetails'))
        if (staffDetails !== null) {
            this.setState({
                userName: staffDetails.userName,
                staffId: staffDetails.staffId,
                userPhone: staffDetails.userPhone,
                userDesignation: staffDetails.userDesignation,
                userKey: staffDetails.userKey,
                userEmail: staffDetails.userId,
                userPassword: '',
                staffAccess: staffDetails.userAccess,
                selectedLocation: staffDetails.userLocation
            })
        } else {
            this.setState({
                userName: '',
                staffId: '',
                userPhone: '',
                userDesignation: '',
                userKey: '',
                userEmail: '',
                userPassword: '',
                staffAccess: '',
                selectedLocation:''
            })
        }
        if(getToken() !== null){
        this._getLocations();
        }
    }

    handleSelectedLocation(e) {
        this.setState({
            showError: false,
            registerFormError: "",
            selectedLocation: e,
        });
    }


    handleUserName(e) {
        const userName = e;
        this.setState({
            showError: false,
            registerFormError: "",
            userName: userName,
        });
    }

    handleStaffId(e) {
        const staffId = e;
        this.setState({
            showError: false,
            registerFormError: "",
            staffId: staffId,
        });
    }


    handleDesignation(e) {
        const userDesignation = e;

        this.setState({
            showError: false,
            registerFormError: "",
            userDesignation: userDesignation,
        });
    }

    handleUserPhone(e) {
        const userPhone = e;

        this.setState({
            showError: false,
            registerFormError: "",
            userPhone: userPhone,
        });
    }

    handleUserEmail(e) {
        const userEmail = e;

        this.setState({
            showError: false,
            registerFormError: "",
            userEmail: userEmail,
        });
    }

    handleUserPassword(e) {
        const userPassword = e;

        this.setState({
            showError: false,
            registerFormError: "",
            userPassword: userPassword,
        });
    }

    handleStaffAccess(e) {
        this.setState({
            staffAccess: e.target.value,
        })
    }


    handleUserProfileImage(e) {
        if (e.target.files[0] != null) {
            this.setState({
                showError: false,
                registerFormError: "",
                userProfileImageLable: e.target.files[0].name,
                userProfileImage: e.target.files[0]
            });
        } else {
            this.setState({
                showError: true,
                registerFormError: "Please select a profile image.",
                userProfileImageLable: "Choose image...",
                userProfileImage: "",
            });
        }
    }

    async handleCreateAccountBtn() {
        const { userName, userEmail, userPassword, staffId, userPhone, userDesignation, staffAccess, userProfileImage } = this.state;

        // const whiteSpaces = /^(?!\s*$)[-a-zA-Z0-9_:,.' ']{1,100}$/;
        const userNameFormate = /^([A-Za-z.\s_-]).{5,}$/;
        const userEmailFormate = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const userPasswordFormate = /(?=.*\d)(?=.*[a-z]).{4,}/;
        const userPhoneFormate = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;

        if (!userName.match(userNameFormate)) {
            this.setState({
                showError: true,
                registerFormError: "Please enter a valid name.",
            });
        } else if (staffId === null) {
            this.setState({
                showError: true,
                registerFormError: "Please enter a valid staff id.",
            });
        } else if (!userPhone.match(userPhoneFormate)) {
            this.setState({
                showError: true,
                registerFormError: "Please enter a valid phone number.",
            });
        } else if (userDesignation === null) {
            this.setState({
                showError: true,
                registerFormError: "Please enter a valid designation.",
            });
        } else if (!userEmail.match(userEmailFormate)) {
            this.setState({
                showError: true,
                registerFormError: "Please enter a valid email address.",
            });
        } else if (this.state.userKey === '' && !userPassword.match(userPasswordFormate)) {
            this.setState({
                showError: true,
                registerFormError: "Use alphanumeric & greater than 5 characters for password.",
            });
        } else if (userProfileImage == null) {
            this.setState({
                showError: true,
                registerFormError: "Please select a profile image.",
                userProfileImageLable: "Choose image...",
            });
        } else {
            if (this.state.userKey !== '') {
                this._updateStaff();
            } else {
                this._addStaff();
            }
        }
    }


    //create the staff
    _addStaff = () => {

        const { userName, userEmail, userPassword, staffId, userPhone, userDesignation, staffAccess, userProfileImage, selectedLocation } = this.state;

        var role = staffAccess === 'Admin' ? 1 : 2
        var dataToSend = {
            USER_ID: userEmail, USER_PASSWORD: userPassword,
            USER_NAME: userName, FKEY_ROLE: role, CREATED_USER: '1', STAFF_ID: staffId,
            USER_DESIGNATION: userDesignation, USER_MOBILE_NUMBER: userPhone,
            FKEY_LOCATION: selectedLocation
        };

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("authorization", 'JWT ' + getToken());

        fetch('http://18.216.10.39:3000/api/users/adduser', {
            method: 'POST',
            body: JSON.stringify(dataToSend),
            headers: myHeaders,
            redirect: 'follow'
        }).then((response) => {
            this.setState({ animating: false })
            console.log("add staff fefefe" + response.status)
            if (response.status === 201) {
                return response.text()
            } else {
                alert('Something went wrong. Please try again later.')
            }
        }).then((jsonResponse) => {
            var responseObject = JSON.parse(jsonResponse);
            //console.log("add staff fefefe" + responseObject[0].USER_ID)

            if (responseObject[0].USER_ID !== undefined) {
                this.props.history.goBack()
            } else {
                //add staff failed
            }

        }).catch((error) => {

        })
    }


    //Update staff details
    _updateStaff = () => {

        const { userName, userEmail, userPassword, staffId, userPhone, userDesignation, staffAccess, userProfileImage,selectedLocation } = this.state;

        var role = staffAccess === 'Admin' ? 1 : 2
        var dataToSend = {
            USER_KEY: this.state.userKey,
            USER_ID: userEmail, USER_PASSWORD: userPassword,
            USER_NAME: userName, FKEY_ROLE: role, CREATED_USER: '1', STAFF_ID: staffId,
            USER_DESIGNATION: userDesignation, USER_MOBILE_NUMBER: userPhone, IS_ACTIVE: 'Y',
            FKEY_LOCATION: selectedLocation
        };

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("authorization", 'JWT ' + getToken());

        fetch('http://18.216.10.39:3000/api/users/updateuserdetails', {
            method: 'PUT',
            body: JSON.stringify(dataToSend),
            headers: myHeaders,
            redirect: 'follow'
        }).then((response) => {
            this.setState({ animating: false })
            if (response.status === 200) {
                return response.text()
            } else {
                alert('Something went wrong. Please try again later.')
            }
        }).then((jsonResponse) => {
            var responseObject = JSON.parse(jsonResponse);

            if (responseObject !== undefined) {
                this.props.history.goBack()
            }

        }).catch((error) => {

        })

    }


    showDeleteAlert = (userKey) => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'Do you want to delete this staff?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Delete',
            cancelButtonText: 'Cancel'
        }).then((result) => {
            if (result.value) {
                this._deleteStaff(userKey);

            }
        })
    }

    _getLocations = () => {
        var authToken = getToken();
        //this.setState({ isLoading: true })
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("authorization", 'JWT ' + authToken);

        fetch('http://18.216.10.39:3000/api/location/getalllocations', {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        }).then((response) => {
//            console.log("staff data" + JSON.stringify(response));
            if (response.status === 200) {
                return response.text()
            } else if (response.status === 403) {
                removeUserSession();
                this.props.history.push('/login')
            } else {
                alert('Something went wrong. Please try again later.')
            }
        })
            .then((responseJson) => {
                var locations = JSON.parse(responseJson);
                this.setState({ locations: locations })
            })
            .catch(error => {
                console.error(error);
                alert(error)
            });

    }


    render() {
        const { showError, registerFormError, userProfileImageLable, staffAccess } = this.state;

        var heading = this.state.userKey !== "" ? "Edit Staff" : "Register Staff";
        var submitButton = this.state.userKey !== "" ? "Update" : "Create";

        let locationOptions = this.state.locations.map(v => (
            <option value={v.PKEY}>{v.LOCATION}</option>
        ));
        return (

            <div>
                <div className="container">
                    <Navigationbar history={this.props.history} />
                </div>

                <div className="container-fluid py-5 bg-light" style={{ flex: 1, marginTop: 80 }}>
                    <div className="col-lg-6 col-md-6 col-sm-12 mx-auto bg-white shadow p-4">
                        <h2 className="text-center mb-4">{heading}</h2>
                        <form action="javascript:void(0)">
                            <div className="form-row">
                                <div className="form-group col-md-6">
                                    <label htmlFor="userFullName">Full Name</label>
                                    <input type="text" className="form-control" id="userName" placeholder="Full Name"
                                        onChange={e => this.handleUserName(e.target.value)} value={this.state.userName} />
                                </div>
                                <div className="form-group col-md-6">
                                    <label htmlFor="userId">Staff Id</label>
                                    <input type="text" className="form-control" id="staffId" placeholder="Staff Id"
                                        onChange={e => this.handleStaffId(e.target.value)} value={this.state.staffId} />
                                </div>
                            </div>

                            <div className="form-row">
                                <div className="form-group col-md-6">
                                    <label htmlFor="designation">Designation</label>
                                    <input type="text" className="form-control" id="Designation" placeholder="Designation"
                                        onChange={(e) => this.handleDesignation(e.target.value)} value={this.state.userDesignation} />
                                </div>

                                <div className="form-group col-md-6">
                                    <label htmlFor="phoneNumber">Mobile Number</label>
                                    <input type="text" className="form-control" id="phoneNumber" placeholder="Mobile Number"
                                        onChange={(e) => this.handleUserPhone(e.target.value)} value={this.state.userPhone} />
                                </div>

                            </div>

                            <div className="form-row">
                                <div className="form-group col-md-6">
                                    <label htmlFor="loginId">Login Id</label>
                                    <input type="email" className="form-control" id="LoginId" placeholder="Login Id"
                                        onChange={(e) => this.handleUserEmail(e.target.value)} value={this.state.userEmail} />
                                </div>

                                <div className="form-group col-md-6">
                                    <label htmlFor="userPassword">Password</label>
                                    <input disabled={this.state.userKey !== ''} type="password" className="form-control" id="userPassword" placeholder="Password"
                                        onChange={(e) => this.handleUserPassword(e.target.value)} value={this.state.userPassword} />
                                </div>

                            </div>

                            <div className="form-row">
                                <div className="form-group col-md-6">
                                    <label htmlFor="staffAccess">Access Type</label>
                                    <select id="staffAccess" className="form-control" value={staffAccess} onChange={this.handleStaffAccess}>
                                        <option defaultValue>Admin</option>
                                        <option>Staff</option>
                                    </select>
                                </div>
                                <div className="form-group col-md-6">
                                    <label htmlFor="designation">Staff Location</label>
                                    <select id="staffLocation" className="form-control" value={this.state.selectedLocation} onChange={(e) => this.handleSelectedLocation(e.target.value)}>
                                        {locationOptions}
                                    </select>
                                </div>

                            </div>

                            <div className="form-group col-md-12">
                                <p >Profile Image</p>
                                <div className="custom-file">
                                    <input type="file" className="custom-file-input" id="userProfileImage" onChange={this.handleUserProfileImage} />
                                    <label className="custom-file-label" htmlFor="userProfileImage">{userProfileImageLable}</label>
                                </div>
                            </div>
                            <p className="text-danger">{showError ? registerFormError : null}</p>
                            <button type="submit" className=" btn text-uppercase justify-content-center"
                                style={{ backgroundColor: '#157FFB', color: '#fff', flex: 1, alignSelf: 'center' }}
                                onClick={this.handleCreateAccountBtn}><b>{submitButton}</b></button>
                        </form>
                    </div>
                </div>
            </div>

        );
    }
}

ReactDOM.render(<AddStaff />, document.getElementById('root'));
export default withRouter(AddStaff);


