import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { withRouter } from "react-router-dom";
import '../App.css';
import '../Grid.css';
import Navigationbar from '../Navigationbar';
import 'bootstrap/dist/css/bootstrap.css';
import { Card, Button } from "react-bootstrap";
import { getToken, removeUserSession } from '../utils/SessionManager';
import Swal from 'sweetalert2'

class Locations extends Component {

    constructor(props) {
        super(props)
        this.state = {
            locations: [],
            locationName:''
        }
    }

    componentDidMount() {

        if (getToken() !== null) {
            this._getLocations();
        }

    }

    _getLocations = () => {
        var authToken = getToken();

        //this.setState({ isLoading: true })
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("authorization", 'JWT ' + authToken);

        fetch('http://18.216.10.39:3000/api/location/getalllocations', {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        }).then((response) => {
            if (response.status === 200) {
                return response.text()
            } else if (response.status === 403) {
                removeUserSession();
                this.props.history.push('/login')
            } else {
                alert('Something went wrong. Please try again later.')
            }
        })
            .then((responseJson) => {
                var locations = JSON.parse(responseJson);
                this.setState({ locations: locations })
            })
            .catch(error => {
                console.error(error);
                alert(error)
            });

    }

    //add  location
    _addLocation = (locationName) => {

       // const { locationName} = this.state;
       
        var dataToSend = {
            LOCATION: locationName
        };

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("authorization", 'JWT ' + getToken());

        fetch('http://18.216.10.39:3000/api/location/createlocation', {
            method: 'POST',
            body: JSON.stringify(dataToSend),
            headers: myHeaders,
            redirect: 'follow'
        }).then((response) => {
            if (response.status === 200) {
                return response.text()
            } else {
                alert('Something went wrong. Please try again later.')
            }
        }).then((jsonResponse) => {
            var responseObject = JSON.parse(jsonResponse);
                //console.log("add_location"+JSON.stringify(responseObject));
                if(responseObject.message !== null){
                    this._getLocations();
                    this._inputElement.value=''
                }

        }).catch((error) => {

        })
    }

    showDeleteAlert = (userKey) => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'Do you want to delete this Location?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Delete',
            cancelButtonText: 'Cancel'
        }).then((result) => {
            if (result.value) {
                this._deleteLocation(userKey);

            }
        })
    }

    _deleteLocation = (userKey) => {

        if(userKey === 28){
            alert("Sorry, you can't delete this location")
            return
        }
        var dataToSend = {
            LOCATION_KEY: userKey
        };
        var authToken = getToken();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("authorization", 'JWT ' + authToken);

        fetch('http://18.216.10.39:3000/api/location/deletelocation', {
            method: 'DELETE',
            body: JSON.stringify(dataToSend),
            headers: myHeaders,
            redirect: 'follow'
        }).then((response) => {
            if (response.status === 200) {
                return response.text()
            } else {
                this.setState({ animating: false })
                alert('Something went wrong. Please try again later.')
            }
        }).then((jsonResponse) => {
            Swal.fire({
                title: 'Success!',
                text: 'Location has been deleted.',
                icon: 'success',
                confirmButtonText: 'OK',
            }).then((result) => {
                if (result.value) {
                    this._getLocations()
                }
            })


        }).catch((error) => {
        })

    }

    renderCard = (card, index) => {
        const { LOCATION, PKEY } = card

        return (
            <div className="column">
                <Card style={{ width: 400 }} key={index} className='box shadow' >

                    <Card.Body >
                        <div style={{flex:1, display:'flex', flexDirection:'row'}}>
                        <Card.Title style={{ textAlign: 'left', color: '#09283B', marginTop:5 }} > {LOCATION}</Card.Title>
                        <img style={{ marginLeft: "auto", width:40}} src={require('../resources/menu.png')} onClick={() => { 
                            this.showDeleteAlert(PKEY) 
                        }}
                            onMouseOver={e => (e.currentTarget.src = require('../resources/staff_avatar.png'))}
                            onMouseOut={e => (e.currentTarget.src = require('../resources/menu.png'))}
                        />
                        </div>
                    </Card.Body>
                </Card>
            </div>

        );
    };

    addItem = (e) => {
        if (this._inputElement.value !== "") {
            
           this._addLocation(this._inputElement.value);

        }else{
            alert('Please enter Location')
        }
        e.preventDefault();
    }

    render() {

        return (

            <div>
                <div className="container">
                    <Navigationbar history={this.props.history} />
                </div>

                <div style={{ flex: 1, marginTop: 100 }}>
                    <h5 className="col-12 col-lg-6 mx-4" style={{ paddingLeft: 60 }}>Locations</h5>
                </div>

                <div style={{ display: 'flex', justifyContent: 'center', marginTop: 50 }} >
                    <div style={{
                        flex: 1, display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <form onSubmit={this.addItem}>
                            <div className="form-row">
                                <input type="text" className="form-control" style={{ width: 300 }} ref={(a) => this._inputElement = a}
                                    placeholder="Enter Location">
                                </input>

                                <button className="btn text-uppercase" style={{ backgroundColor: '#E30052', color: '#fff',marginLeft: 20 }} type="submit">Add</button>
                            </div>

                        </form>
                    </div>
                </div>

                <div style={{display:'flex',justifyContent:'center',}} >
                    <div>{this.state.locations.map(this.renderCard)}</div>
                </div>
            </div>

        );
    }
}

ReactDOM.render(<Locations />, document.getElementById('root'));
export default withRouter(Locations);


