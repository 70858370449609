import React, { Component, useState } from 'react';
import ReactDOM from 'react-dom';
import { withRouter } from "react-router-dom";
import { View, } from '@react-pdf/renderer';
import '../App.css';
import '../Grid.css';
import Navigationbar from '../Navigationbar';
import 'bootstrap/dist/css/bootstrap.css';
import { getToken, removeUserSession } from '../utils/SessionManager';
import Swal from 'sweetalert2'

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from "@fullcalendar/interaction";
import { CustomDialog, useDialog } from 'react-st-modal';
import Modal from 'react-awesome-modal';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


class Schedule extends Component {

    state = {
        weekendsVisible: true,
        visible: false,
        startDate: new Date(),
        endDate: new Date(),
        calendarApi: null,
        staffKey: 'Select Staff',
        patientKey: 'Select Patient',
        staffName: '',
        patientName: null,
        eventsData: [],
        staffList: [{
            USER_KEY: 1000,
            USER_NAME: 'Select Staff'
        }],
        patientList: [{
            PKEY: 1000,
            PATIENT_NAME: 'Select Patiemt'
        }],
    }

    componentDidMount() {

        if (getToken() !== null) {
            this._getAllEvents();
            this._getAllUsers();
            this._getAllPatients();
        }

        this.setState({
            startDate: new Date().setDate(1)
        })

        this.setState({
            endDate: new Date()
        })

    }

    openModal() {
        this.setState({
            visible: true
        });
    }

    closeModal = () => {
        this.setState({
            visible: false
        });
    }

    handleStartDateChange = (date) => {
        if (date <= this.state.endDate) {
            this.setState({
                startDate: date
            })
        } else {
            alert("Start date cannot be greater than end date");
        }

    }

    handleEndDateChange = (date) => {
        if (date >= this.state.startDate) {
            this.setState({
                endDate: date,
            })

        } else {
            alert("End date cannot be lesser than start date");
        }
    }

    render() {

        var allEvents = [];
        var colors = [{ coloring: 'red' }, { coloring: 'green' }, { coloring: 'brown' }, { coloring: 'orange' }, { coloring: 'blue' },
        { coloring: 'grey' }, { coloring: 'orange' }, { coloring: 'blue' }]

        if (this.state.eventsData !== null) {
            var eventsData = this.state.eventsData
            var i = 0;
            eventsData.map(v => {
                allEvents.push({
                    title: v.STAFF_NAME,
                    start: new Date(v.FROM_DATE),
                    end: new Date(v.TO_DATE),
                    eventKey: v.SCHEDULE_KEY,
                    patientName: v.PATIENT_NAME,
                    allDay: true
                    // backgroundColor: colors[i].coloring
                })

                i = i + 1;

            })
        }

        const staffItems = this.state.staffList.map(v => (
            <option value={v.USER_KEY}>{v.USER_NAME}</option>
        ));

        const patientItems = this.state.patientList.map(v => (
            <option value={v.PKEY}>{v.PATIENT_NAME}</option>
        ));

        const startDate = new Date(this.state.startDate);
        var month = startDate.getMonth();
        var year = startDate.getFullYear();

        var dialogEndDate = new Date(this.state.endDate);
        dialogEndDate.setDate(dialogEndDate.getDate() - 1)
        return (
            <div>
                <div className="container">
                    <Navigationbar history={this.props.history} />
                </div>
                <div className="container-fluid py-5 bg-light" style={{ marginTop: 80 }}>

                    <FullCalendar
                        initialView='dayGridMonth'
                        editable={false}
                        selectable={true}
                        dayMaxEvents={true}
                        selectMirror={true}
                        weekends={this.state.weekendsVisible}
                        headerToolbar={{
                            left: "prev",
                            center: "title",
                            right: "next"
                        }}
                        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                        events={allEvents}
                        select={this.handleDateSelect}
                        eventContent={renderEventContent} // custom render function
                        eventClick={this.handleEventClick}
                        eventsSet={this.handleEvents}
                        showNonCurrentDates={true} // called after events are initialized/added/changed/removed
                    /* you can update a remote database when these fire:
                    eventAdd={function(){}}
                    eventChange={function(){}}
                    eventRemove={function(){}}
                    */
                    />


                    <Modal visible={this.state.visible} width="400" effect="fadeInUp" onClickAway={() => this.closeModal()}>
                        <div>
                            <div style={{ flex: 1, display: 'flex', marginTop: 20, justifyContent: 'center' }}>
                                <h4>Schedule Duty</h4>
                            </div>

                            <View style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'row', marginTop: 20, marginBottom: 20, marginLeft: 10, marginRight: 10 }}>
                                <View style={{ display: 'flex', flexDirection: 'column' }}>
                                    <label style={{ marginLeft: 10, marginBottom: -5 }} htmlFor="startDate">Start Date</label>
                                    <DatePicker
                                        className="form-control"
                                        dateFormat="dd-MM-yyyy"
                                        selected={this.state.startDate}
                                        onChange={this.handleStartDateChange}
                                        maxDate={new Date(year, month + 1, 0)}
                                    />

                                </View>
                                <View style={{ display: 'flex', flexDirection: 'column', marginLeft: 50 }}>
                                    <label style={{ marginLeft: 10, marginBottom: -5 }} htmlFor="endDate">End Date</label>
                                    <DatePicker
                                        className="form-control"
                                        dateFormat="dd-MM-yyyy"
                                        selected={dialogEndDate}
                                        onChange={this.handleEndDateChange}
                                        maxDate={new Date(year, month + 1, 0)}
                                    />

                                </View>
                            </View>

                            <div style={{ marginLeft: 20, marginRight: 20 }}>
                                <label style={{ marginBottom: -10 }} htmlFor="staffName">Select Staff</label>
                                <select id="staff" className="form-control" value={this.state.staffKey} onChange={this.handleStaffSelection} >
                                    {staffItems}
                                </select>

                                <label style={{ marginTop: 20, marginBottom: -10 }} htmlFor="patientName">Select Patient</label>
                                <select id="patient" className="form-control" value={this.state.patientKey} onChange={this.handlePatientSelection} >
                                    {patientItems}
                                </select>
                            </div>

                            <div style={{ marginTop: 20, marginLeft: 20, marginRight: 20, marginBottom: 20, flex: 1, display: 'flex', justifyContent: 'center' }}>
                                <button type="submit" className=" btn text-uppercase justify-content-center"
                                    style={{ backgroundColor: '#157FFB', color: '#fff', flex: 1, alignSelf: 'center', marginRight: 10 }}
                                    onClick={this.closeModal}><b>Close</b></button>

                                <button type="submit" className=" btn text-uppercase justify-content-center"
                                    style={{ backgroundColor: '#157FFB', color: '#fff', flex: 1, alignSelf: 'center', marginLeft: 10 }}
                                    onClick={this.handleCreateEvent}><b>Add</b></button>
                            </div>
                        </div>
                    </Modal>
                </div>
            </div>
        )

    }

    handleCreateEvent = () => {

        const { staffKey, patientKey, startDate, endDate } = this.state;

        var dataToSend = {
            FKEY_STAFF: staffKey, FKEY_PATIENT: patientKey,
            FKEY_LOCATION: 28, FROM_DATE: startDate, TO_DATE: endDate,
            FROM_TIME: null, FROM_TIME: null

        };

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("authorization", 'JWT ' + getToken());

        fetch('http://18.216.10.39:3000/api/schedular/createSchedule', {
            method: 'POST',
            body: JSON.stringify(dataToSend),
            headers: myHeaders,
            redirect: 'follow'
        }).then((response) => {
            if (response.status === 200) {

                return response.text()
            } else {
                alert('Something went wrong. Please try again later.')
            }
        }).then((jsonResponse) => {
            var responseObject = JSON.parse(jsonResponse);
            this.closeModal();
            this._getAllEvents();
            console.log(jsonResponse);

        }).catch((error) => {

        })
    }

    handleStaffSelection = (staff) => {
        this.setState({ staffKey: staff.target.value, staffName: staff.target.options[staff.target.selectedIndex].text })
    }

    handlePatientSelection = (patient) => {
        this.setState({ patientKey: patient.target.value, patientName: patient.target.options[patient.target.selectedIndex].text })

    }

    handleWeekendsToggle = () => {
        // this.setState({
        //     weekendsVisible: !this.state.weekendsVisible
        // })
    }

    handleDateSelect = (selectInfo) => {
        var todateDate = new Date();

        if (new Date(selectInfo.startStr) < todateDate) {
            alert("Please select future date to schedule duty")
        } else {
            let calendarApi = selectInfo.view.calendar
            var endDate = new Date(selectInfo.endStr);

            // clear date selection
            this.setState({
                startDate: new Date(selectInfo.startStr),
                endDate: endDate,
                calendarApi: calendarApi,
            }, () => { this.openModal(); })
        }

    }

    handleEventClick = (clickInfo) => {

        Swal.fire({
            title: 'Are you sure?',
            text: `Do you want to delete this event '${clickInfo.event.title}' ?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Delete',
            cancelButtonText: 'Cancel'
        }).then((result) => {
            if (result.value) {
                this.handleDeleteEvent(clickInfo.event.extendedProps.eventKey);
            }
        })

    }

    handleEvents = (events) => {
        // this.setState({
        //     currentEvents: events
        // })
    }

    handleDeleteEvent = (eventKey) => {
        var dataToSend = {
            SCHEDULE_KEY: eventKey
        };
        var authToken = getToken();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("authorization", 'JWT ' + authToken);

        fetch('http://18.216.10.39:3000/api/schedular/deleteSchedule', {
            method: 'DELETE',
            body: JSON.stringify(dataToSend),
            headers: myHeaders,
            redirect: 'follow'
        }).then((response) => {
            if (response.status === 200) {
                return response.text()
            } else {
                this.setState({ animating: false })
                alert('Something went wrong. Please try again later.')
            }
        }).then((jsonResponse) => {
            this._getAllEvents();


        }).catch((error) => {
        })

    }


    _getAllEvents = () => {

        var dataToSend = {
            FKEY_LOCATION: 28,
        };
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("authorization", 'JWT ' + getToken());

        fetch('http://18.216.10.39:3000/api/schedular/getScheduleByLocation', {
            method: 'POST',
            body: JSON.stringify(dataToSend),
            headers: myHeaders,
            redirect: 'follow'
        }).then((response) => {
            console.log("schedule"+JSON.stringify(response))
            if (response.status === 200) {

                return response.text()
            } else {
                alert('Something went wrong. Please try again later.')
            }
        }).then((jsonResponse) => {
            var responseObject = JSON.parse(jsonResponse);
            this.setState({ eventsData: responseObject })

        }).catch((error) => {

        })

    }

    _getAllUsers = () => {
        var authToken = getToken();

        //this.setState({ isLoading: true })
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("authorization", 'JWT ' + authToken);

        fetch('http://18.216.10.39:3000/api/users/getalluser', {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        }).then((response) => {
            if (response.status === 200) {
                return response.text()
            } else if (response.status === 403) {
                removeUserSession();
                this.props.history.push('/login')
            } else {
                alert('Something went wrong. Please try again later.')
            }
        })
            .then((responseJson) => {
                var staffResponse = JSON.parse(responseJson);
                this.setState({ staffList: staffResponse })
                this.setState({ staffKey: staffResponse[0].USER_KEY })

            })
            .catch(error => {
                console.error(error);
                alert(error)
            });

    }

    _getAllPatients = () => {
        var authToken = getToken();

        //this.setState({ isLoading: true })
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("authorization", 'JWT ' + authToken);

        fetch('http://18.216.10.39:3000/api/patient/getallpatients', {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        }).then((response) => {
            if (response.status === 200) {
                return response.text()
            } else if (response.status === 403) {
                removeUserSession();
                this.props.history.push('/login')
            } else {
                alert('Something went wrong. Please try again later.')
            }
        })
            .then((responseJson) => {
                var patientResponse = JSON.parse(responseJson);
                this.setState({ patientList: patientResponse })
                this.setState({ patientKey: patientResponse[0].PKEY })

            })
            .catch(error => {
                console.error(error);
                alert(error)
            });

    }
}

function renderEventContent(eventInfo) {
    return (
        <>
            <b>{eventInfo.event.title}</b>
            <i style={{ marginLeft: 5 }}>{eventInfo.event.extendedProps.patientName}</i>
        </>
    )
}

ReactDOM.render(<Schedule />, document.getElementById('root'));
export default withRouter(Schedule);
