import React from "react";
import {
    Page,
    Text,
    View,
    Document,
    StyleSheet,
    Font,
    Image
} from "@react-pdf/renderer";

Font.register({
    family: 'Open Sans',
    fonts: [
    { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-700.ttf', fontWeight: 800 }
    ]
    });

const COL1_WIDTH = 20
const COLN_WIDTH = (100 - 60) / 2

const styles = StyleSheet.create({
    page: {
        padding: 40,
        marginTop: 50,
        backgroundColor: "#ffffff"
    },
    table: {
        display: "table",
        width: "auto",
        marginTop: 30,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: 'grey'

    },
    tableRow: {
        margin: "auto",
        flexDirection: "row",
    },
    tableHeaderRow: {
        margin: "auto",
        flexDirection: "row",
        backgroundColor: '#239DFF',
    },
    tableCol1Header: {
        width: COL1_WIDTH + '%',
        borderColor: '#bfbfbf',
        borderBottomColor: '#000',
        border: 'none',
        justifyContent:'center'
    },
    tableColHeader: {
        width: COLN_WIDTH + '%',
        borderBottomColor: '#000',
        borderLeftWidth: 0,
        borderTopWidth: 0,
        border: 'none',
        justifyContent:'center'
    },
    tableCol1: {
        width: COL1_WIDTH + "%",
        borderColor: '#bfbfbf',
        border: 'none',
        justifyContent:'center'
    },
    tableCol: {
        width: COLN_WIDTH + "%",
        border: 'none',
        justifyContent:'center'
    },
    tableCellHeader: {
        margin: "auto",
        margin: 5,
        fontSize: 12,
        fontWeight: 500,
        color:'white',
        textAlign:'center',
    },
    tableCell: {
        margin: "auto",
        margin: 5,
        padding: 5,
        fontSize: 10,
        textAlign:'center',
    },

});


export function PdfDocument(props) {

    var name;
    var id;
    var designation;
    var totalTime = 0;
    var month;
    props.data.map((a, index) => {
        name = a.name;
        id = a.id;
        designation = a.designation
        totalTime += a.time
        month = a.date
    })

    var date = new Date(month)

    var attendanceMonth = date.getMonth()
    // console.log('month number' + attendanceMonth)
    let monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    let monthName = monthNames[attendanceMonth];

    var seconds = totalTime * 60;
    var hours = parseInt(seconds / 3600);
    seconds = seconds % 3600;
    var minutes = parseInt(seconds / 60); // 60 seconds in 1 minute
    var totalTimeWorked
    if (hours === 0) {
        var totalTimeWorked = minutes + ' mins'
    } else {
        if (minutes === 0) {
            var totalTimeWorked = hours + ' hrs'
        } else {
            var totalTimeWorked = hours + ' hrs : ' + minutes + ' mins'
        }
    }
    return (
        <Document>
            <Page style={styles.page}>
                <View style={{ display: 'flex', flex: 1, justifyContent: 'center', alignContent: 'center' }}>
                   <Text style={{ color: '#155F99', alignSelf: 'center',fontFamily:'Open Sans' }}>{monthName.toUpperCase()}</Text>
                    <Text style={{ color: '#155F99', alignSelf: 'center', marginTop: 5, fontFamily:'Open Sans' }}>ATTENDANCE REPORT</Text>
                </View>

                <View style={{ height: 5, backgroundColor: '#155F99', marginTop: 10, width: '100%' }}></View>


                <View style={{ flex: 1, marginTop: 15, flexDirection: 'row' }}>
                    <Text style={{ flex: 1, color: '#155F99', textAlign: 'left', fontSize: '16', fontFamily:'Open Sans'}}>{'NAME:'}</Text>
                    <Text style={{ flex: 2, color: '#239DFF', fontSize: '18', fontWeight: 'normal' }}>{name}</Text>
                </View>
                <View style={{ flex: 1, flexDirection: 'row' }}>
                    <Text style={{ flex: 1, color: '#155F99', textAlign: 'left',fontSize: '16', fontFamily:'Open Sans' }}>{'EMP ID:'}</Text>
                    <Text style={{ flex: 2, color: '#239DFF', }}>{id}</Text>
                </View>
                <View style={{ flex: 1, flexDirection: 'row' }}>
                    <Text style={{ flex: 1, color: '#155F99', textAlign: 'left',fontSize: '16', fontFamily:'Open Sans' }}>{'DESIGNATION:'}</Text>
                    <Text style={{ flex: 2, color: '#239DFF', }}>{designation}</Text>
                </View>
                <View style={{ flex: 1, flexDirection: 'row' }}>
                    <Text style={{ flex: 1, color: '#155F99', textAlign: 'left',fontSize: '16', fontFamily:'Open Sans' }}>{'TOTAL HOURS:'}</Text>
                    <Text style={{ flex: 2, color: '#239DFF', }}>{totalTimeWorked}</Text>
                </View>


                <View style={{ height: 5, backgroundColor: '#155F99', marginTop: 10, width: '100%' }}></View>


                <View style={styles.table}>
                    
                    <View style={[styles.tableHeaderRow, {height:40}]}>
                        <View style={styles.tableCol1Header}>
                            <Text style={styles.tableCellHeader}>No</Text>
                        </View>
                        <View style={styles.tableColHeader}>
                            <Text style={styles.tableCellHeader}>Date</Text>
                        </View>
                        <View style={styles.tableCol1Header}>
                            <Text style={styles.tableCellHeader}>Sign In</Text>
                        </View>
                        <View style={styles.tableCol1Header}>
                            <Text style={styles.tableCellHeader}>Sign Out</Text>
                        </View>
                        <View style={styles.tableColHeader}>
                            <Text style={styles.tableCellHeader}>Time</Text>
                        </View>
                        
                    </View>

                    {props.data
                        ? props.data.map((a, index) => {
                            var seconds = a.time * 60;
                            var hours = parseInt(seconds / 3600);
                            seconds = seconds % 3600;
                            var minutes = parseInt(seconds / 60); // 60 seconds in 1 minute
                            var timeWorked
                            if (hours === 0) {
                                var timeWorked = minutes + ' mins'
                            } else {
                                if (minutes === 0) {
                                    var timeWorked = hours + ' hrs'
                                } else {
                                    var timeWorked = hours + ' hrs : ' + minutes + ' mins'
                                }
                            }
                            var date_format = new Date(a.date);
                            date_format = date_format.getDate() + '-' + date_format.getMonth() + '-' + date_format.getFullYear()
                            var signInDate = new Date(a.signIn)
                            var signInTime = signInDate.getHours() + ':' + signInDate.getMinutes()

                            var signOutDate = new Date(a.signOut)
                            var signOutTime = signOutDate.getHours() + ':' + signOutDate.getMinutes()

                            var backGroundCellColor = 'white'
                            if (index % 2 == 0) {
                                backGroundCellColor = 'white'
                            } else {
                                 backGroundCellColor = '#F6F6F6'
                            }
                            return (

                                <View style={[styles.tableRow, { backgroundColor: backGroundCellColor }]}>
                                    <View style={styles.tableCol1}>
                                        <Text style={styles.tableCell}>{index + 1}</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>{date_format}</Text>
                                    </View>
                                    <View style={styles.tableCol1}>
                                        <Text style={styles.tableCell}>{signInTime}</Text>
                                    </View>
                                    <View style={styles.tableCol1}>
                                        <Text style={styles.tableCell}>{signOutTime}</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>{timeWorked}</Text>
                                    </View>
                                    
                                </View>
                            )
                        })
                        : ''}
                </View>

            </Page>
        </Document>
    );
}