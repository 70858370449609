import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css'
import Avatar from 'react-avatar';
import { Navbar, NavDropdown } from 'react-bootstrap'
import { getUser, removeUserSession } from './utils/SessionManager';


class Navigationbar extends Component {
    constructor() {
        super()
        this.state = {
            homeIconLink: '/'
        }
    }

    async componentDidMount() {
        if (this.props.user) {
        }
    }

    static getDerivedStateFromProps(props) {
        if (props.user) {

        } else {

        }
    }

    goToHome = () => {
        this.props.history.push('/')
    }

    handleLogOutBtn = () => {
        removeUserSession();
        this.props.history.push('/login')
    }

    _renderWithOutLogin() {
        var user = getUser();

        return (
            <ul className="navbar-nav ml-auto">

                <li className="nav-item">
                    {/* <span className="nav-link active text-uppercase mr-2">{user}</span> */}
                    <NavDropdown className="nav-link active text-uppercase mr-2" title={user} id="dropdown-basic">
                    <NavDropdown.Item className="text-lowercase" onClick={this.handleLogOutBtn}>Logout</NavDropdown.Item>
                </NavDropdown>
                </li>

                <li className="nav-item">
                    <div style={{ height: 50, width: 50 }}>
                        <Avatar size='40' round={true} src={require('./resources/staff_avatar.png')} />
                    </div>
                </li>

                {/* <NavDropdown title="Dropdown" id="dropdown-basic">
                    <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                    <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
                    <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>
                </NavDropdown> */}
            </ul>


        )
    }

    render() {
        const { updated_user, homeIconLink } = this.state
        return (
            // Navbar
            <div class="container fixed-top col-lg-12" style={{ backgroundColor: '#09283B', width: '100%', height: 70, paddingLeft: 50, paddingRight: 50 }}>

                <Navbar variant="dark" expand="lg">

                    {/* Brand image */}
                    <a className="col-12 col-lg-3 mx-n3" >
                        <Navbar.Brand onClick={this.goToHome} >
                            <span className="navbar-brand" >
                                <img style={{ width: 150 }} alt="Chronometry Logo" src={require("./resources/logo.png")} />
                            </span>
                        </Navbar.Brand>
                    </a>

                    {/* Collapse button */}
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />

                    {/* Navbar Links */}
                    <Navbar.Collapse id="basic-navbar-nav">
                        {this._renderWithOutLogin()}
                    </Navbar.Collapse>

                </Navbar>
            </div>
        );
    }
}


export default Navigationbar;

